<template>
  <v-container
    id="usager-profile"
    fluid
    tag="section"
  >
    <v-row justify="center">
      <v-col
        cols="12"
        md="8"
      >
        <base-material-card icon="mdi-account-outline">
          <template v-slot:after-heading>
            <div class="font-weight-light card-title mt-2">
              {{ $t('editionUsager.edition_profil') }}
              <span class="body-1">— {{ $t('editionUsager.completer_votre_profil') }}</span>
            </div>
          </template>
          <v-form
            ref="profilForm"
          >
            <v-container class="py-0">
              <v-row>
                <v-col
                  cols="12"
                  md="7"
                >
                  <v-text-field
                    v-model="usager.courriel"
                    :label="$t('general.courriel')"
                    data-cy="courrielUsager"
                    class="purple-input"
                    :rules="validationEmail"
                    validate-on-blur
                    @blur="onCourrielBlur()"
                  >
                    <template #label>
                      <span class="red--text"><strong>* </strong></span>{{ $t('general.courriel') }}
                    </template>
                  </v-text-field>
                </v-col>

                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-model="usager.prenom"
                    data-cy="prenomUsager"
                    :label="$t('general.prenom')"
                    class="purple-input"
                    :rules="validationChampRequis"
                  >
                    <template #label>
                      <span class="red--text"><strong>* </strong></span>{{ $t('general.prenom') }}
                    </template>
                  </v-text-field>
                </v-col>

                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-model="usager.nom"
                    data-cy="nomUsager"
                    :label="$t('general.nom_de_famille')"
                    class="purple-input"
                    :rules="validationChampRequis"
                  >
                    <template #label>
                      <span class="red--text"><strong>* </strong></span>{{ $t('general.nom_de_famille') }}
                    </template>
                  </v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    v-model="usager.adresse.rue"
                    data-cy="rueUsager"
                    :label="$t('general.adresse')"
                    class="purple-input"
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="3"
                >
                  <v-text-field
                    v-model="usager.adresse.ville"
                    data-cy="villeUsager"
                    :label="$t('general.ville')"
                    class="purple-input"
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="3"
                >
                  <v-text-field
                    v-model="usager.adresse.province"
                    data-cy="provinceUsager"
                    :label="$t('general.province')"
                    class="purple-input"
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="3"
                >
                  <v-text-field
                    v-model="usager.adresse.pays"
                    data-cy="paysUsager"
                    :label="$t('general.pays')"
                    class="purple-input"
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="3"
                >
                  <v-text-field
                    v-model="usager.adresse.codePostal"
                    data-cy="codePostalUsager"
                    class="purple-input"
                    :label="$t('general.code_postal')"
                  />
                </v-col>
                <v-col
                  cols="12"
                  class="text-right"
                >
                  <v-btn
                    color="primary"
                    class="mr-0"
                    data-cy="btnSauvegarderProfil"
                    @click="onSauvegarder()"
                  >
                    {{ $t('general.sauvegarder') }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </base-material-card>
      </v-col>

      <v-col
        cols="12"
        md="4"
      >
        <base-material-card
          class="v-card-profile"
          :avatar="require('@/assets/avatar.jpeg')"
        >
          <v-card-text class="text-center">
            <h6 class="display-1 mb-1 grey--text">
              {{ $t('general.profil') }}
            </h6>

            <h4 class="display-2 font-weight-light mb-3 black--text">
              {{ usager.firstName }} {{ usager.lastName }}
            </h4>

            <p class="font-weight-light grey--text">
              {{ usager.presentation }}
            </p>
          </v-card-text>
        </base-material-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        md="8"
      >
        <base-material-card icon="mdi-lock-outline">
          <template v-slot:after-heading>
            <div class="font-weight-light card-title mt-2">
              {{ $t('editionUsager.edition_mot_de_passe') }}
            </div>
          </template>
          <v-form
            ref="motPasseForm"
            v-model="motPasseFormValid"
          >
            <v-container class="py-0">
              <v-row>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-model="motPasse"
                    class="purple-input"
                    name="password"
                    type="password"
                    autocomplete="new-password"
                    data-cy="motPasseUsager"
                    :rules="validationChampRequis"
                    required
                  >
                    <template #label>
                      <span class="red--text"><strong>* </strong></span>{{ $t('general.mot_de_passe') }}
                    </template>
                  </v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-model="motPasseValidation"
                    class="purple-input"
                    type="password"
                    data-cy="motPasseValidationUsager"
                    autocomplete="new-password"
                    :rules="validationMotPasse"
                  >
                    <template #label>
                      <span class="red--text"><strong>* </strong></span>{{ $t('general.mot_de_passe_validation') }}
                    </template>
                  </v-text-field>
                </v-col>

                <v-col
                  cols="12"
                  class="text-right"
                >
                  <v-btn
                    color="primary"
                    class="mr-0"
                    :disabled="!motPasseFormValid"
                    data-cy="btnSauvegarderMotPasse"
                    @click="onChangerMotPasse()"
                  >
                    {{ $t('general.sauvegarder') }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import restApiService from '@/services/restApiService.js'
  import i18n from '@/i18n.js'

  export default {
    data: () => ({
      usager: {
        roles: 'user',
        creePar: 'Toucan-client',
        adresse: {
        },
      },
      motPasseFormValid: true,
      courrielUsager: '',
      motPasse: '',
      motPasseValidation: '',
      validationChampRequis: [
        v => !!v || i18n.t('general.le_champ_est_requis'),
      ],
      validationEmail: [
        v => !!v || i18n.t('general.le_champ_est_requis'),
        v => /.+@.+/.test(v) || i18n.t('general.veuillez_saisir_une_adresse_email_valide'),
      ],
    }),
    computed: {
      validationMotPasse() {
        return [
          v => !!v || i18n.t('general.le_champ_est_requis'),
          v => this.motPasse === this.motPasseValidation || i18n.t('editionUsager.vos_mots_de_passe_ne_correspondent_pas'),
        ]
      },
    },
    mounted () {
      restApiService
        .get(`/api/usagers/${this.$route.params.idUsager}`)
        .then((result) => {
          this.usager = result.data
          this.courrielUsager = this.usager.courriel
          this.$refs.profilForm.resetValidation()
        })
        .catch((erreur) => alert(erreur))
    },
    methods: {
      async onSauvegarder () {
        var valide = this.$refs.profilForm.validate()
        if (valide) {
          if (this.usager.courriel !== this.courrielUsager) {
            this.usager.nomUsager = this.usager.courriel
            this.usager.user.login = this.usager.courriel
            this.usager.user.email = this.usager.courriel
          }
          restApiService
            .put('/api/usagers', this.usager)
            .then(() => {
              this.$dialog.notify.success(i18n.t('general.sauvegarde'))
            })
            .catch((erreur) => alert(erreur))
        }
      },
      onChangerMotPasse () {
        var valide = this.$refs.motPasseForm.validate()
        if (valide && this.motPasse) {
          restApiService
            .put('/api/users/motPasse/' + this.usager.nomUsager, { motPasse: this.motPasse })
            .then(() => {
              this.motPasseValidation = ''
              this.motPasse = ''
              this.$refs.motPasseForm.resetValidation()
              this.$dialog.notify.success(i18n.t('general.sauvegarde'))
            })
            .catch((erreur) => alert(erreur))
        }
      },
      onCourrielBlur () {
        if (this.usager.courriel && this.courrielUsager !== this.usager.courriel) {
          restApiService
            .get('/api/usagers/existe/' + this.usager.courriel)
            .then((result) => {
              if (result.data.existe) {
                this.$dialog.error({
                  text: i18n.t('editionUsager.un_autre_usager_possede_deja_cette_adresse_courriel'),
                  title: i18n.t('general.erreur'),
                  actions: {
                    true: {
                      text: 'Ok',
                    },
                  },
                }).then(() => {
                  this.usager.courriel = this.courrielUsager
                })
              }
            }).catch((erreur) => alert(erreur))
        }
      },
    },
  }
</script>
